import React, { useContext } from 'react';
import { string, shape } from 'prop-types';

import classnames from 'classnames';
import { Image } from 'nordic/image';
import get from 'lodash/get';

import Link from '../../link';
import { StaticPropsContext } from '../../context/static-props';
import { trackEvent } from '../../../lib/melidata';

const namespace = 'ui-search-header--official-store';

const OfficialStoreHeader = ({ className, logo, title, subtitle, url }) => {
  const { lowEnd } = useContext(StaticPropsContext);

  return (
    <div className={classnames(namespace, className)}>
      <Link
        className={`${namespace}__link`}
        href={url}
        title={title}
        tabIndex="-1"
        isInternal={false}
        onClick={() =>
          trackEvent({ path: '/search/official_store_logo/click', event_data: { store: title, url }, clean: true })
        }
      >
        <Image
          alt={title}
          className={`${namespace}__image`}
          src={get(logo, 'pictures.2x')}
          lowEnd={lowEnd}
          lazyload="off"
        />
      </Link>
      <div className={`${namespace}__description`}>
        <h3 className={`${namespace}__title`}>{title}</h3>
        <h4 className={`${namespace}__subtitle`}>{subtitle}</h4>
      </div>
    </div>
  );
};

OfficialStoreHeader.propTypes = {
  className: string,
  logo: shape({
    pictures: shape({
      '2x': string.isRequired,
    }),
  }).isRequired,
  subtitle: string.isRequired,
  title: string.isRequired,
  url: string.isRequired,
};

OfficialStoreHeader.defaultProps = {
  className: '',
};

export default OfficialStoreHeader;
